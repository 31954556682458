<template>
  <div>
    <div class="w-25 mb-3">
      <label for="status">Filter Status:</label>
      <v-select
        v-model="selectedStatus"
        :options="statusOptions"
        label="text"
        value="value"
        @input="fetchOrders()"
        placeholder="Select a status"
      ></v-select>
    </div>
    <my-table :rows="rows" :columns="columns" :isLoading="isLoading">
      <template v-slot="{ props }">
        <span v-if="props.column.field === 'id'">
          <b-button
            variant="gradient-primary"
            block
            :to="`/Chartered-truck-delivery-summary/${props.row.id}`"
          >
            C{{ props.row.id }}
          </b-button>
        </span>
        <div
          v-if="props.column.field === 'order_type'"
          class="d-flex justify-content-center"
        >
          <b> {{ props.row.lorry }} </b>
        </div>
        <div
          v-if="props.column.field === 'name'"
          class="d-flex justify-content-center"
        >
          <b> {{ props.row.sender.name }} </b>
        </div>
        <div
          v-if="props.column.field === 'address'"
          class="d-flex justify-content-center"
        >
          <b> {{ props.row.sender.complete }} </b>
        </div>
        <div
          v-if="props.column.field === 'pickup_date'"
          class="d-flex justify-content-center"
        >
          <b>
            {{ $helpers.formatDate(props.row.pickup_date) }}
          </b>
        </div>
        <div
          v-if="props.column.field === 'pickup'"
          class="d-flex justify-content-center"
        >
          <template
            v-if="
              props.row.chartered_orders && props.row.chartered_orders.length
            "
          >
            <span>{{ props.row.chartered_orders.length }}</span>
          </template>
          <template v-else>
            <!-- Handle the case when chartered_orders is empty or undefined -->
            <span>N/A</span>
          </template>
        </div>
        <div
          v-if="props.column.field === 'weight'"
          class="d-flex justify-content-center"
        >
          <b> {{ props.row.weight }}</b>
        </div>
        <div
          v-if="props.column.field === 'quantity'"
          class="d-flex justify-content-center"
        >
          <b> {{ props.row.quantity }}</b>
        </div>
        <span v-if="props.column.field === 'status'">
          <b-badge
            class="p-1 w-100"
            :variant="
              props.row.status.value === 13
                ? 'purple'
                : props.row.status.color_name
            "
          >
            {{ getStatusText(props.row) }}
          </b-badge>
        </span>

        <div
          v-if="props.column.field === 'quotation'"
          class="d-flex justify-content-center"
        >
          {{ props.row.quote ? 'RM ' + props.row.total_price : 'RM 0.00' }}
        </div>
        <div
          v-if="props.column.field === 'document'"
          class="d-flex justify-content-center"
        >
          <b-button
            variant="secondary"
            class="rounded"
            @click="openModallist(props.row.id)"
          >
            <feather-icon
              icon="InfoIcon"
              size="15"
              class="text-white align-middle mr-25"
            />
            View
          </b-button>
        </div>
        <div
          v-if="props.column.field === 'action'"
          class="d-flex justify-content-center"
        >
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              :to="`/Chartered-truck-delivery-summary/${props.row.id}`"
            >
              <span>View Details</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="props.row.status.value == 14"
              @click="retryPayment(props.row)"
            >
              <span>Pay Now</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="props.row.status.value == 12"
              @click="getQuotes(props.row.user_chartered_quote)"
            >
              <span>View Quotes</span>
            </b-dropdown-item>
            <b-dropdown-item @click="cancelOrder(props.row.id)">
              <span>Cancel Order</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template></my-table
    >

    <!-- QUOTES LIST MODAL -->
    <b-modal
      size="lg"
      v-model="modalVisible"
      title="Quotes List"
      hide-footer
      centered
    >
      <div v-if="quotes">
        <ul>
          <li v-for="(quote, index) in quotes" :key="index">
            <b-row class="mb-2">
              <b-col cols="6"
                ><div>
                  <strong>Description:</strong> {{ quote.description }}<br />
                  <strong>ID:</strong> {{ quote.id }}<br />
                  <strong>Order ID:</strong> {{ quote.order_id }}<br />
                  <strong>Delivery ETA:</strong> {{ quote.delivery_date }}<br />
                  <strong>Total Amount:</strong> {{ quote.total }}
                </div></b-col
              >
              <b-col cols="7">
                <div class="d-flex">
                  <b-button
                    variant="secondary"
                    class="rounded mr-1"
                    @click="openQuoteModallist(index)"
                  >
                    <feather-icon
                      icon="InfoIcon"
                      size="15"
                      class="text-white align-middle mr-25"
                    />
                    View Document
                  </b-button>
                  <b-button
                    class="mr-1"
                    variant="warning"
                    @click="rejectBid(index, quote)"
                    >Reject</b-button
                  >
                  <b-button variant="success" @click="acceptBid(quote)"
                    >Accept</b-button
                  >
                </div>
              </b-col>
            </b-row>
            <hr />
          </li>
        </ul>
      </div>
      <div v-else>
        <p>No quotes available.</p>
      </div>
    </b-modal>

    <!-- QUOTE AND DOCUMENT MODAL PREVIEW -->
    <b-modal
      ref="modal"
      size="lg"
      :title="modalType === 'quote' ? 'Quoted Document' : 'Customer Document'"
      no-close-on-backdrop
      hide-footer
      centered
    >
      <div
        class="overflow-auto d-flex align-items-center justify-content-center"
        @success="handleModalClosure"
      >
        <template>
          <div>
            <iframe
              v-if="isPDF(src)"
              :src="src"
              frameborder="3"
              width="700"
              height="750"
              :allowfullscreen="true"
            />
            <div v-else class="text-center">No preview available.</div>
          </div>
        </template>
      </div>
    </b-modal>

    <!-- QUOTE AND DOCUMENT MODAL LIST -->
    <b-modal
      ref="modallist"
      size="lg"
      :title="'Customer Document List'"
      no-close-on-backdrop
      hide-footer
      centered
    >
      <div @success="handleModalClosure">
        <div>
          <div class="d-flex justify-content-center align-items-center mb-2">
            <hr class="flex-grow-1" />
            <span class="mx-2 font-weight-bold"> Quoted Documents </span>
            <hr class="flex-grow-1" />
          </div>
          <div class="text-center">
            <div
              class="d-flex justify-content-center flex-wrap"
              v-if="order.quote && order.quote.documents.length"
            >
              <div
                v-for="(document, index) in order.quote.documents"
                :key="index"
                class="d-flex my-1"
              >
                <b-button
                  @click="openModal(document.path)"
                  class="d-flex align-items-center btn-sm mr-1 ml-2"
                  variant="outline-primary"
                >
                  <feather-icon icon="FileIcon" size="25" />
                  {{ document.name }}</b-button
                >
              </div>
            </div>
            <div v-else class="d-flex justify-content-center">
              <div>
                <a> No Quoted Document </a>
              </div>
            </div>
          </div>
        </div>

        <div class="my-2">
          <div class="d-flex justify-content-center align-items-center mb-2">
            <hr class="flex-grow-1" />
            <span class="mx-2 font-weight-bold"> Customer Documents </span>
            <hr class="flex-grow-1" />
          </div>
          <div class="text-center">
            <div
              class="d-flex justify-content-center flex-wrap"
              v-if="order.customer_documents && order.customer_documents.length"
            >
              <div
                v-for="(document, index) in order.customer_documents"
                :key="index"
                class="d-flex my-1"
              >
                <b-button
                  @click="openModal(document.document)"
                  class="d-flex align-items-center btn-sm mr-1 ml-2"
                  variant="outline-primary"
                >
                  <feather-icon icon="FileIcon" size="25" />
                  {{ document.document_name }}</b-button
                >
                <b-button
                  class="btn-sm"
                  @click="deleteFile(index)"
                  variant="primary"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="15"
                    class="text-white align-middle"
                  />
                </b-button>
              </div>
            </div>
            <div v-else class="d-flex justify-content-center">
              <div>
                <a> No Customer Document </a>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-1">
            <b-button
              class="btn-sm"
              @click="triggerFileInput"
              variant="success"
            >
              <feather-icon
                icon="PlusIcon"
                size="15"
                class="text-white align-middle"
              />
              Add
            </b-button>
            <input
              type="file"
              ref="fileInput"
              style="
                opacity: 0;
                position: absolute;
                left: -9999px;
                top: -9999px;
              "
              @change="uploadFile"
            />
          </div>
        </div>

        <div class="mb-2">
          <div class="d-flex justify-content-center align-items-center mb-2">
            <hr class="flex-grow-1" />
            <span class="mx-2 font-weight-bold"> Receipts </span>
            <hr class="flex-grow-1" />
          </div>
          <div
            v-if="
              order.payment_session_id || order.payment_method === 'LOGPOINT'
            "
            class="text-center"
          >
            <b-row>
              <b-col cols="3">
                <div class="rounded border px-2 py-1">
                  <feather-icon
                    style="color: red"
                    icon="DownloadIcon"
                    size="15"
                  />

                  <a href="#" @click.prevent="handleDownload('do')">
                    Delivery Order
                  </a>
                </div></b-col
              >
              <b-col cols="3">
                <div class="rounded border px-2 py-1">
                  <feather-icon
                    style="color: red"
                    icon="DownloadIcon"
                    size="15"
                  />
                  <a href="#" @click.prevent="handleDownload('inv')">
                    Invoice
                  </a>
                </div></b-col
              >
              <b-col cols="3">
                <div class="rounded border py-1">
                  <feather-icon
                    style="color: red"
                    icon="DownloadIcon"
                    size="15"
                  />
                  <a href="#" @click.prevent="handleDownload('grn')">
                    Good Return Note
                  </a>
                </div></b-col
              >
              <b-col cols="3">
                <div class="rounded border px-2 py-1">
                  <feather-icon
                    style="color: red"
                    icon="DownloadIcon"
                    size="15"
                  />

                  <a href="#" @click.prevent="handleDownload('cover')">
                    Covernote
                  </a>
                </div></b-col
              >
            </b-row>
          </div>
          <div v-else class="d-flex justify-content-center">
            <div>
              <a> No Receipts </a>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- QUOTED MODAL LIST -->
    <b-modal
      ref="modalquotelist"
      size="lg"
      :title="'Quoted Documents List'"
      no-close-on-backdrop
      hide-footer
      centered
    >
      <div
        class="overflow-auto align-items-center justify-content-center"
        @success="handleModalClosure"
      >
        <div>
          <div class="text-center">
            <div class="d-flex justify-content-center flex-wrap">
              <div>
                <div
                  v-if="
                    quotes[modalIndex] && quotes[modalIndex].documents.length
                  "
                >
                  <div
                    v-for="(document, docIndex) in quotes[modalIndex].documents"
                    :key="docIndex"
                    class="d-flex align-items-center my-1"
                  >
                    <b-button
                      @click="openModal(document.path)"
                      class="d-flex align-items-center btn-sm mr-1 ml-2"
                      variant="outline-primary"
                    >
                      <feather-icon icon="FileIcon" size="25" />
                      {{ document.name }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div>
                <a> No Quoted Document </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import vSelect from 'vue-select'

export default {
  components: {
    MyTable,
    vSelect
  },
  data() {
    return {
      isLoading: false,
      selectedStatus: null,
      statusOptions: [
        { text: 'For Quote', value: 17 },
        { text: 'Quoted', value: 12 },
        { text: 'Accepted', value: 2 },
        { text: 'Picked', value: 3 },
        { text: 'Arrived at origin', value: 4 },
        { text: 'In transit', value: 5 },
        { text: 'Arrived at Destination Port', value: 6 },
        { text: 'Out For Delivery', value: 7 },
        { text: 'Request For Cancellation', value: 9 },
        { text: 'Cancelled', value: 10 },
        { text: 'Pending Payment', value: 14 }
      ],
      columns: [
        {
          label: 'Order ID',
          field: 'id',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Lorry Type',
          field: 'order_type',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Sender Name',
          field: 'name',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Sender Address',
          field: 'address',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Pick Up Date',
          field: 'pickup_date',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Total Orders',
          field: 'pickup',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Total Weight',
          field: 'weight',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Total Quantity',
          field: 'quantity',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Quotation',
          field: 'quotation',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Documents',
          field: 'document',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Action',
          field: 'action',
          tdClass: 'text-center align-middle'
        }
      ],
      rows: [],
      quotes: [{}],
      order: {},
      src: '',
      modalType: null,
      modalIndex: null,
      modalVisible: false
    }
  },
  created() {
    this.fetchOrders()
  },
  methods: {
    getStatusText(row) {
      let statusText
      switch (row.status.value) {
        case 17:
          statusText = 'For Quote'
          break
        case 12:
          statusText = 'Quoted'
          break
        case 2:
          statusText = 'Accepted'
          break
        case 3:
          statusText = 'Picked Up'
          break
        case 4:
          statusText = 'Arrived at Origin'
          break
        case 5:
          statusText = 'In Transit'
          break
        case 6:
          statusText = 'Arrived at Destination Port'
          break
        case 7:
          statusText = 'Out For Delivery'
          break
        case 8:
          statusText = 'Delivered'
          break
        case 9:
          statusText = 'Request For Cancellation'
          break
        case 10:
          statusText = 'Cancelled'
          break
        case 14:
          statusText = 'Pending Payment'
          break
        default:
          statusText = row.status.text
      }
      return statusText
    },

    fetchOrders() {
      this.isLoading = true
      if (this.selectedStatus) {
        this.$http
          .get(
            `orders?orders_for=chartered&status=${this.selectedStatus.value}`
          )
          .then((response) => {
            if (response.data.data?.length > 0) {
              this.rows = [...response.data.data]
            } else {
              this.rows = []
            }
            this.isLoading = false
          })
        return
      }
      this.$http.get(`orders?orders_for=chartered`).then((response) => {
        if (response.data.data?.length > 0) {
          this.rows = [...response.data.data]
        } else {
          this.rows = []
        }
        this.isLoading = false
      })
    },

    getQuotes(orders) {
      this.quotes = orders
      this.modalVisible = true
    },

    acceptBid(quote) {
      this.$router.push({
        name: 'Charteredtruckdeliveryproceedorder',
        params: {
          id: quote.order_id,
          smarttruckid: quote.id
        }
      })
    },

    rejectBid(index, quote) {
      this.$swal({
        title: 'Are you sure you want to reject this bid?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'No',
        cancelButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-danger mr-1',
          cancelButton: 'btn btn-success'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.isConfirmed == false) {
          this.isLoading = true
          this.$http.get(`reject_quote/${quote.id}`).then((response) => {
            if (response) {
              if (index !== -1 && index < this.quotes.length) {
                this.quotes.splice(index, 1)
              }
              this.$swal({
                title: 'Rejected!',
                icon: 'success',
                text: 'Bid Rejected',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-success'
                },
                buttonsStyling: false
              })
            }
            this.isLoading = false
          })
          this.isLoading = false
        }
      })
    },

    cancelOrder(id) {
      this.$swal({
        title: 'Are you sure you want to cancel this order?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'No',
        cancelButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-danger mr-1',
          cancelButton: 'btn btn-success'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.isConfirmed == false) {
          this.isLoading = true
          this.$http.get(`cancel_fleet_order/${id}`).then((response) => {
            if (response) {
              this.$swal({
                title: 'Rejected!',
                icon: 'success',
                text: 'Order Cancelled',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-success'
                },
                buttonsStyling: false
              })
              this.fetchOrders()
            }
            this.isLoading = false
          })
        }
      })
    },

    openModallist(id) {
      this.isLoading = true
      this.$http.get(`chartered_order?id=${id}`).then((response) => {
        this.order = response.data.data
        this.$refs.modallist.show()
      })
      this.isLoading = false
    },

    openQuoteModallist(index) {
      this.modalIndex = index
      this.$refs.modalquotelist.show()
    },

    openModal(type) {
      this.src = type
      this.$refs.modal.show()
    },

    handleModalClosure() {
      this.modalType = null
      this.modalIndex = null
    },

    triggerFileInput() {
      this.$refs.fileInput.click()
    },

    uploadFile(event) {
      this.$swal({
        title: 'Confirm Upload',
        text: 'Do you want to upload this file?',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Yes',
        confirmButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-warning mr-2',
          cancelButton: 'btn btn-success'
        }
      }).then((result) => {
        if (!result.isConfirmed) {
          const formData = new FormData()
          formData.append('order_id', this.order.id)
          formData.append('documents[0]', event.target.files[0])

          this.$http
            .post(`upload_order_documents`, formData)
            .then((response) => {
              this.$swal('Success', 'File uploaded successfully!', 'success')
              this.openModallist(this.order.id)
            })
            .catch((error) => {
              this.$swal('Error', 'File upload failed!', 'error')
            })
        } else {
          this.$refs.fileInput.value = null
          this.$swal('Canceled', 'File upload canceled.', 'info')
        }
      })
    },

    isPDF(url) {
      return url.toLowerCase().endsWith('.pdf')
    },

    deleteFile(index) {
      this.$swal({
        title: 'Confirm Deletion',
        text: 'Are you sure you want to delete this document?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Yes',
        confirmButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-warning mr-2',
          cancelButton: 'btn btn-success'
        }
      }).then((result) => {
        if (!result.isConfirmed) {
          this.$http
            .get(`remove_order_docs/${this.order.customer_documents[index].id}`)
            .then((response) => {
              this.$swal('Deleted', 'File deleted successfully!', 'success')
              this.openModallist(this.order.id)
            })
            .catch((error) => {
              this.$swal('Error', 'File deletion failed!', 'error')
            })
          this.order.customer_documents.splice(index, 1)
        }
      })
    },

    async handleDownload(type) {
      let link = ''
      let fileName = ''
      if (type === 'inv') {
        link = `/download-invoice/${
          this.order.payment.id ? this.order.payment.id : this.order.logpoint_id
        }/${this.order.payment_method}/chartered`
        fileName = `invoice-${this.order.id}`
      } else {
        link = `chartered-delivery-confirmed/${
          this.order.payment_session_id
            ? this.order.payment_session_id
            : this.order.logpoint_id
        }/${this.order.payment_method}/print/${type}`
        fileName = `${type}-${this.order.id}`
      }
      const response = await this.$http.get(link, {
        responseType: 'blob'
      })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', `${fileName}.pdf`)
      document.body.appendChild(fileLink)
      fileLink.click()
    },

    async retryPayment(row) {
      const form = { orders: { id: {} } }
      form.orders.id.id = row.id
      form.orders.id.price = row.total_price
      form.orders.id.smarttruck_cut = 0
      form.orders.id.golog_cut = row.golog_cut ? row.golog_cut : 0
      form.total_charge = row.total_price
      const response = await this.$http.post('place-delivery', form)
      window.location.href = response.data.url
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
